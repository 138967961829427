




























































import { postController } from '@/modules/common/dialogs/post/post-controller'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  postController = postController
  dialogValueChange(value) {
    if (!value) {
      this.postController.changeOpenBlindReasonDialog(false)
    }
  }
}
